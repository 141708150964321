<style lang="scss" scoped>

</style>
<template>

    <div>
        <el-dialog title="携程包车额外费用明细"
                   width="420px"
                   :close-on-click-modal="false"
                   custom-class="el-dialog-middle-center"
                   class="mod-driver-confirm"
                   :visible.sync="orderInfo.visibleCostShow">
            <div class="check-demand-info">
                <ul class="demand-info-list">
                    <li><span class="dialog-cost-title">超公里费：</span>{{orderInfo.exceedsDistancePrice | currency}}元</li>
                    <li><span class="dialog-cost-title">超时长费：</span>{{orderInfo.exceedsTimePrice | currency}}元</li>
                    <li><span class="dialog-cost-title">其他费用：</span>{{orderInfo.otherPrice | currency}}元</li>
                    <li><span class="dialog-cost-title">超过的时间：</span>{{orderInfo.exceedsTime || '-'}}分钟</li>
                    <li><span class="dialog-cost-title">超过的距离：</span>{{orderInfo.exceedsDistance || '-'}}公里</li>
                    <li><span class="dialog-cost-title">预计用车公里数：</span>{{orderInfo.expectedDistanceLength || '-'}}公里
                    </li>
                    <li><span class="dialog-cost-title">预计用车时间：</span>{{orderInfo.expectedTime || '-'}}分钟</li>
                    <li><span class="dialog-cost-title">超公里单价：</span>{{orderInfo.exceedsDistanceUnitPrice | currency}}元
                    </li>
                    <li><span class="dialog-cost-title">超时长单价：</span>{{orderInfo.exceedsTimeUnitPrice | currency}}元</li>
                    <li><span class="dialog-cost-title">面付金额：</span>{{orderInfo.faceFee | currency}}元</li>
                    <li><span class="dialog-cost-title">空驶距离：</span>{{orderInfo.kongShiDistance || '0'}}公里</li>
                    <li><span class="dialog-cost-title">空驶费用：</span>{{orderInfo.kongShiAmount | currency}}元</li>
                </ul>
            </div>
        </el-dialog>
    </div>

</template>

<script type="text/ecmascript-6">
    export default {
        //定义模版数据
        data() {
            return {

            }
        },
        props: {
            orderInfo: {
                type: Object,
                default: function () {
                    return {}
                }
            }
        },
        components: {},
        //计算属性
        computed: {},
        //主件被加载完成
        mounted: function () {

        },
        //定义事件方法
        methods: {
        },
        //监听模版变量
        watch: {}

    }
</script>

