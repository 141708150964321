<style lang="scss" scoped>
    .list-content {
        height: auto;
        display: flex;
        border-bottom: 1px solid #F0F2F5;
        padding-bottom: 20px;
        background: #ffffff;
        padding-top: 10px;
        word-wrap: break-word;
        word-break: break-all;

        p {
            /*line-height: 24px;*/
            span {
                color: #909399;
                display: inline-block;

                &:first-child {
                    width: 85px;
                }
            }
        }









        .content {
            /*width: calc(100% - 100px);*/
            line-height: 18px;
        }
    }

    .item-bar {
        display: flex;
    }

    .item-bar-label {
        min-width: 85px;
        color: #909399;
    }

    .list-content /deep/ {
        .finance-confirm {
            margin-top: 5px;

            .el-input-group {
                border-radius: 4px;
                width: 155px;
            }

            .el-input__inner {
                height: 30px;
                line-height: 30px;
                padding: 0 12px;
                font-size: 12px;
            }

            .el-input-group__append {
                vertical-align: baseline;
            }

            .el-button {
                padding: 9px 12px;
                background: #1989FA;
                border-color: #1989FA;
                color: #fff;
                border-radius: 0 4px 4px 0;
                font-size: 12px;
            }
        }
    }

</style>
<template>

    <div class="list-content clear">
        <order-main-first :orderInfo="orderInfo" :userInfo="userInfo" :orderType="orderType" ></order-main-first>
        <order-main-second :orderInfo="orderInfo" :userInfo="userInfo" :orderType="orderType"></order-main-second>
        <order-main-third :orderInfo="orderInfo" :userInfo="userInfo" :orderType="orderType" :type="type"></order-main-third>
        <order-main-fourth :info="orderInfo" :userInfo="userInfo" :orderType="orderType" :orderInfoIndex="orderInfoIndex"
                           @updateOrderList="updateOrderList"
                           @updateCTripCost="handleCTripCost" :type="type">
        </order-main-fourth>
        <order-main-dialog :orderInfo="orderInfo"></order-main-dialog>
    </div>

</template>

<script type="text/ecmascript-6">
    import orderMainFirst from '../orderMainFirst/index';
    import orderMainSecond from '../orderMainSecond/index';
    import orderMainThird from '../orderMainThird/index';
    import orderMainFourth from '../orderMainFourth/index';
    import orderMainDialog from '../orderMainDialog/index';

    export default {
        props: {
            orderInfo: {
                type: Object,
                default: function () {
                    return {};
                }
            },
            userInfo: {
                type: Object,
                default: function () {
                    return {};
                }
            },
            orderInfoIndex: {
                type: Number,
                default: null
            },
            itineraryInfo: {
                type: String,
                default: null
            },
            orderType: {
                type: String,
                default: null
            },
            type: {
                type: String,
                default: 'userCar'
            }
        },
        components: {
            orderMainFirst,
            orderMainSecond,
            orderMainThird,
            orderMainFourth,
            orderMainDialog
        },
        //定义事件方法
        methods: {
            updateOrderList(){
                this.$emit('updateOrderList')
            },
            handleCTripCost(orderInfoIndex) {
                this.$emit('updateCTripCost', orderInfoIndex)
            }, // 携程包车额外费用明细
        }

    }
</script>
