<style lang="scss" scoped functional>
p {
    span {
        color: #909399;
        display: inline-block;

        &:first-child {
            width: 85px;
        }
    }
}

.list-content-second {
    width: 360px;
    /*margin-left: 10px;*/
    /*height: 100%;*/
    border-right: 1px solid #F0F2F5;
    font-size: 14px;

    p {
        line-height: 24px;
    }

    .second-content {
        /*font-size: 12px;*/
        padding: 0 10px; /*height: calc(100% - 20px);*/
    }
}

.item-bar {
    display: flex;
}

.item-bar-label {
    min-width: 85px;
    color: #909399;
}
</style>
<template>
    <div class="list-content-second">
        <div class="second-content">
            <p>
                <span>出行时间：</span>
                <span style="color: #333;"
                      v-if="orderInfo.productFormName === '门票'||(orderInfo.productFormName === '向导服务'&&orderInfo.resourceUseCar===0)">
                    {{ getOrderInfo.reserveTime | dateCus('yyyy-MM-dd') }}
                </span>
                <span style="color: #333;" v-else>
                    {{ getOrderInfo.reserveTime | dateCus('yyyy-MM-dd hh:mm') }}
                    <!--                   <span style="color: #333;" v-if="getOrderInfo.reserveEndTime">-->
                    <!--                       - {{-->
                    <!--                           getOrderInfo.reserveEndTime | dateDayCus-->
                    <!--                       }} {{ getOrderInfo.reserveEndTime | dateCus('hh:mm') }}-->
                    <!--                   </span>-->
                </span>
            </p>
            <p v-if="orderInfo.thirdOrderInfoDto&&orderInfo.thirdOrderInfoDto.lastPushDriverTime">
                <span style="width: 100px">最晚派单时间：</span>
                <span style="color:  rgb(255, 59, 48);font-weight: bold">
                    {{ orderInfo.thirdOrderInfoDto.lastPushDriverTime | dateCus('yyyy-MM-dd hh:mm') }}
                </span>
            </p>
            <div v-if="orderInfo.thirdOrderInfoDto&&orderInfo.thirdOrderInfoDto.departDelayTime"
                 style="font-size: 12px;padding-left: 85px;color: #999;margin-bottom: 5px">
                含航班到达后延迟{{ orderInfo.thirdOrderInfoDto.departDelayTime }}分钟用车
            </div>
            <p v-if="orderType === 'insuranceList'">
                <span>结束时间：</span>
                <span style="color: #333;">
                    {{ getOrderInfo.tripEndTime | dateCus('yyyy-MM-dd hh:mm') }}
                </span>
            </p>
            <p v-if="orderType === 'insuranceList' && getOrderInfo.tripStartTime">
                <span style="width: 105px">实际开始时间：</span>
                <span style="color: #333;">
                    {{ getOrderInfo.tripStartTime | dateCus('yyyy-MM-dd hh:mm') }}
                </span>
            </p>
            <p v-if="orderType === 'insuranceList'">
                <span style="width: 105px">实际行驶距离：</span>
                <span style="color: #333;">
                    {{ getOrderInfo.distanceLength / 1000 || '-' }}公里
                </span>
            </p>
            <div style="display: inline-block" v-if="getOrderInfo.isDeparture">
                <div style="float: left;width: 85px;color: #909399;">出发地址：</div>
                <div style="float: left;width: 250px" class="content" v-if="getOrderInfo.departure">
                    {{
                        (getOrderInfo.departure.city || '') + ' ' + (getOrderInfo.departure.title ||
                            getOrderInfo.departure.value || '')
                    }}
                    <p style="font-size: 12px;color: #666;line-height: 1.5em">{{ getOrderInfo.departure.address }}</p>
                </div>
            </div>
            <p style="line-height: 18px" v-else>
                <span>出发地址：</span>{{ getOrderInfo.departure || '-' }}
            </p>
            <div style="display: inline-block" v-if="getOrderInfo.viaPoints&&getOrderInfo.viaPoints.length">
                <div style="float: left;width: 85px;color: #909399;">途经信息：</div>
                <div style="float: left;width: 250px" class="content">
                    <div v-for="(vm,index) in getOrderInfo.viaPoints" :key="vm.id">
                        <span v-if="vm.pointType">{{ vm.pointType == 1 ? '上客' : '下客' }}</span>
                        <span>途经{{ index + 1 }}：{{ vm.name }}</span>
                    </div>
                </div>
            </div>
            <div v-if="!['21'].includes(getOrderInfo.productForm)">
                <div style="display: inline-block" v-if="getOrderInfo.isDestination">
                    <div style="float: left;width: 85px;color: #909399;">送达地址：</div>
                    <div style="float: left;width: 250px" class="content" v-if="getOrderInfo.destination">
                        {{
                            (getOrderInfo.destination.city || '') + ' ' + (getOrderInfo.destination.title ||
                                getOrderInfo.destination.value || '')
                        }}
                        <p style="font-size: 12px;color: #666;line-height: 1.5em">
                            {{
                                getOrderInfo.destination.address
                            }}
                        </p>
                    </div>
                </div>
                <p style="line-height: 18px" v-else>
                    <span>送达地址：</span>{{
                        getOrderInfo.productForm != 24 && getOrderInfo.destination ? getOrderInfo.destination : '-'
                    }}
                </p>
            </div>
            <p>
                <span>出行天数：</span>{{ orderInfo.tripDay || '1' }}
            </p>
            <p v-if="orderType !== 'insuranceList'">
                <span>出行人数：</span>{{ orderInfo.playPersonNumber ? orderInfo.playPersonNumber : '-' }}
            </p>
            <div style="display: flex;">
                <div style="width: 85px;color: #909399;">出行人：</div>
                <edit-passenger-dialog :orderInfo="orderInfo"/>
                <PassengerListPopover v-if="orderInfo.tripUserInfo" :orderInfo="orderInfo"
                                      :info="orderInfo.tripUserInfo"/>
            </div>
            <div v-if="orderType !== 'insuranceList'">
                <p>
                    <span>邮箱：</span>{{ orderInfo.tripUserEmail || '-' }}
                </p>
                <p>
                    <span>紧急联系人/代订人：</span>
                    {{ orderInfo.contactUserName ? orderInfo.contactUserName : '-' }}{{
                        orderInfo.contactUserMobile ? ',' :
                            ''
                    }}&nbsp;{{ orderInfo.contactUserMobile }}
                </p>
                <div style="display: flex">
                    <span style="min-width: 85px;color: #909399;">备注：</span>
                    <div style="flex: 1;" class="content">
                        {{ orderInfo.orderRemake ? orderInfo.orderRemake : '-' }}
                    </div>
                </div>
                <p>
                    <span>航班号：</span>{{ orderInfo.flightNumber || '-' }}
                </p>
                <p v-if="[12, 18, 23, 24].includes(orderInfo.sourceType)"
                   :class="{'color-error':orderInfo.thirdOrderInfoDto.isVip}">
                    <span>VIP订单:</span>{{ orderInfo.thirdOrderInfoDto.isVip ? '是VIP订单' : '否' }}</p>
            </div>
        </div>
    </div>

</template>

<script type="text/ecmascript-6">
import PassengerListPopover from './PassengerListPopover'
import EditPassengerDialog from "@/www/pages/orderManage/components/orderMainSecond/edit-passenger-dialog.vue";

export default {
    filters: {
        dateDayCus(time) {
            const date = new Date().toLocaleDateString();
            const date1 = new Date(time).toLocaleDateString();
            if (date !== date1)
                return '次日'
            return ''
        }
    },
    props: {
        orderInfo: {
            type: Object,
            default: function () {
                return {}
            }
        },
        userInfo: {
            type: Object,
            default: function () {
                return {};
            }
        },
        orderType: {
            type: String,
            default: null
        }
    },
    //计算属性
    computed: {
        getOrderInfo() {
            let orderInfo = JSON.parse(JSON.stringify(this.orderInfo));
            try {
                if (orderInfo.departure) {
                    if (typeof orderInfo.departure === 'string')
                        orderInfo.departure = JSON.parse(orderInfo.departure)
                    orderInfo.isDeparture = true;
                }
                if (orderInfo.destination) {
                    if (typeof orderInfo.destination === 'string')
                        orderInfo.destination = JSON.parse(orderInfo.destination)
                    orderInfo.isDestination = true;
                }
            } catch (err) {
                console.log(err);
            }
            if (orderInfo.orderViaPointDtos && orderInfo.orderViaPointDtos.length) {
                orderInfo.viaPoints = [...orderInfo.orderViaPointDtos]
                if (`${orderInfo.viaPoints[0].lon},${orderInfo.viaPoints[0].lat}` === orderInfo.departure.location) {
                    orderInfo.viaPoints.shift();
                }
            }
            //按日包车 没有出行时间、出发地址、结束地址 需要从行程信息里面解析
            if (orderInfo.productType == 2 && orderInfo.orderCustomizeInfoDto) {
                let itineraryInfo = JSON.parse(orderInfo.orderCustomizeInfoDto.itineraryInfo);
                orderInfo.departure = itineraryInfo[0].tripStartPlace
                orderInfo.isDeparture = true;
                orderInfo.destination = itineraryInfo[itineraryInfo.length - 1].tripEndPlace
                orderInfo.isDestination = true;
                orderInfo.reserveTime = `${itineraryInfo[0].date} ${itineraryInfo[0].handTripTime}`;
            }
            return orderInfo
        }
    },
    components: {
        PassengerListPopover,
        EditPassengerDialog
    },

}
</script>

