<style lang="scss" scoped>
p {
    /*line-height: 24px;*/
    span {
        color: #909399;
        display: inline-block;

        &:first-child {
            width: 85px;
        }
    }
}

.list-content-third {
    width: 296px;
    /*margin-left: 10px;*/
    border-right: 1px solid #F0F2F5;
    padding: 0 10px;
    font-size: 14px;

    p {
        line-height: 24px;
    }

    .third-top {
        /*height: 104px;*/
        /*font-size: 12px;*/
    }

    .third-bottom {
        margin-top: 20px;
        /*height: 104px;*/
    }
}

.item-bar {
    display: flex;
}

.item-bar-label {
    min-width: 85px;
    color: #909399;
}
</style>
<template>

    <div class="list-content-third">
        <div class="third-top">
            <div v-if="orderType !== 'insuranceList'">
                <p style="font-weight: 700"><span>类型：</span>
                    <span style="color: #333">{{ orderInfo.productForm ? (orderInfo.productFormName) : '-' }}</span>
                    <span style="color: #333;margin-left: 5px" v-if="orderInfo.sourceType===20"> (扫码下单)</span>
                </p>
                <p v-if="orderInfo.productForm!=='18'">
                    <span>是否用车：</span>{{
                        orderInfo.resourceUseCar == 1 || ['18', '21'].includes(orderInfo.productForm) ? '用车' : '非用车'
                    }}</p>
            </div>

            <div style="display: inline-block">
                <div style="float: left;width: 85px;color: #909399;">产品：</div>
                <div style="float: left;width: 210px" class="content">
                    <span v-if="orderInfo.productNo||orderInfo.productName">
                           <span style="color: #319BF7" v-if="orderInfo.productNo">{{
                                   orderInfo.productNo
                               }}&nbsp;</span>
                        <!--不能有代叫用车的名字出现在产品 做兼容--->
                        <span>
                        {{
                                orderInfo.productName && orderInfo.productName.replace('代叫用车', '即时预约')
                            }}
                    </span>
                    </span>
                    <span style="color: #319BF7" v-else>-</span>
                </div>
            </div>
            <div style="display: inline-block" v-if="orderType !== 'insuranceList'">
                <div style="float: left;width: 85px;color: #909399;">资源：</div>
                <div style="float: left;width: 210px" class="content"><span
                    style="color: #319BF7">{{
                        orderInfo.resourceNo ? orderInfo.resourceNo : '-'
                    }}</span>&nbsp;{{ orderInfo.resourceName }}
                </div>
            </div>
            <p v-if="orderInfo.expectedTime">
                <span>预计时长：</span>
                {{ Math.floor(orderInfo.expectedTime / 60) }}小时{{ orderInfo.expectedTime % 60 }}分钟
            </p>
            <p v-if="orderInfo.expectedDistanceLength">
                <span>预计公里数：</span>{{ orderInfo.expectedDistanceLength }}公里 <font
                v-if="orderInfo.isHighSpeedPriority===1" class="color-error">高速优先</font></p>
            <!--<p><span>目的地：</span>{{orderInfo.productDestCityName||'-'}}</p>-->
            <p><span>车辆：</span><span style="color: #333"
                                       v-if="orderInfo.resourcePriceWay==0&&orderInfo.productType==1">专车</span><span
                style="color: #333" v-if="orderInfo.resourcePriceWay==1&&orderInfo.productType==1">拼车</span>
                {{ formatCarModel(orderInfo.vehicleType).name }}</p>
            <p><span>订单联系人：</span>
                {{ orderInfo.orderContractName ? orderInfo.orderContractName : '-' }}{{
                    orderInfo.orderContractPhone ?
                        ',' : ''
                }}{{ orderInfo.orderContractPhone }}</p>
            <p v-if="type === 'userCar'">
                <span>保险：</span>{{ orderInfo.orderInsuranceStatus == 0 ? '无' : '《道路承运人责任险》' }}</p>
            <p v-if="orderType === 'insuranceList' && orderInfo.insuranceTime">
                <span>投保时间：</span>{{ orderInfo.insuranceTime | dateCus('yyyy-MM-dd hh:mm') }}</p>
        </div>
        <div class="third-bottom">
            <div v-if="orderInfo.guiderName">
                <p><span>导游姓名：</span>{{ orderInfo.guiderName }}</p>
                <p v-if="orderInfo.distributionGuiderDto&&orderInfo.distributionGuiderDto.touristGuideMobile">
                    <span>导游手机号：</span>{{
                        orderInfo.distributionGuiderDto.touristGuideMobile
                    }}</p>
            </div>
            <div>
                <div v-if="orderInfo.distributionGuiderDto">
                    <div v-if="orderInfo.resourceUseCar == 0&&!orderInfo.guiderName">
                        <p v-if="orderInfo.distributionGuiderDto.touristGuideName"><span>司导：</span>
                            {{ orderInfo.distributionGuiderDto.touristGuideName }}</p>
                        <p v-if="orderInfo.distributionGuiderDto.touristGuideMobile"><span>司导手机号：</span>
                            {{ orderInfo.distributionGuiderDto.touristGuideMobile }}</p>
                    </div>
                    <div v-if="orderInfo.resourceUseCar == 1||orderInfo.distributionGuiderDto.guiderName">
                        <p><span>司机名称：</span> {{ orderInfo.distributionGuiderDto.guiderName }}</p>
                        <p><span>司机手机号：</span>{{ orderInfo.distributionGuiderDto.guiderMobile }}</p>
                        <p><span>车牌号：</span>{{ orderInfo.distributionGuiderDto.carNumber }}</p>
                        <!--<p>车座车型：{{orderInfo.orderRemake}}</p>-->
                        <p><span>车辆品牌：</span>{{
                                (orderInfo.distributionGuiderDto.carBrand || '-') + ' ' +
                                (orderInfo.distributionGuiderDto.carModel || "")
                            }}</p>
                        <p><span>驾驶证号：</span>{{ orderInfo.distributionGuiderDto.driverLicenseNumber || '-' }}</p>
                        <p v-if="orderType !== 'insuranceList'"><span style="width: 120px">网约车资格证号：</span>{{
                                orderInfo.distributionGuiderDto.certificateNo ||
                                '-'
                            }}
                        </p>
                        <p><span>行驶证号：</span>{{ orderInfo.distributionGuiderDto.vin || '-' }}</p>
                        <p v-if="orderType !== 'insuranceList'"><span style="width: 140px">网约车营运资格证号：</span>{{
                                orderInfo.distributionGuiderDto.certificate ||
                                '-'
                            }}
                        </p>
                    </div>
                </div>
                <div v-else>
                    <p>暂无司机信息</p>
                </div>
            </div>
        </div>
    </div>

</template>

<script type="text/ecmascript-6">
import {getSeatModel} from '@/data/index';


export default {
    //定义模版数据
    data() {
        return {}
    },
    props: {
        orderInfo: {
            type: Object,
            default: function () {
                return {}
            }
        },
        userInfo: {
            type: Object,
            default: function () {
                return {};
            }
        },
        orderType: {
            type: String,
            default: null
        },
        type: {
            type: String,
            default: 'userCar'
        }
    },
    components: {},
    //计算属性
    computed: {},
    //主件被加载完成
    mounted: function () {

    },
    //定义事件方法
    methods: {
        formatCarModel(type) {
            let info = {};
            if (type) {
                info = getSeatModel(parseInt(type));
            }
            return info;
        },
    },
    //监听模版变量
    watch: {}

}
</script>

