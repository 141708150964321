<template>
    <div style="display:inline-block;">
        <span>{{ amount | currency }}</span>
        <el-popover placement="bottom" width="200" title="订单变更" v-model="visible" v-if="userInfo.refType==0">
            <el-form ref="form" :model="form" :rules="rules">
                <el-form-item :label="label" prop="value">
                    <el-input v-model="form.value" placeholder="输入金额" type="number"/>
                </el-form-item>
            </el-form>
            <div style="text-align: right; margin: 0">
                <el-button size="mini" type="text" @click="visible = false">取消</el-button>
                <el-button type="primary" size="mini" @click="handleSubmit" :loading="loading">确定</el-button>
            </div>
            <el-button slot="reference" size="mini" type="text" style="margin-left: 10px">
                <i class="el-icon-edit-outline"></i>
                变更
            </el-button>
        </el-popover>
    </div>
</template>

<script>
import {updateOrderAmountByOrderId} from '@/www/urls/orderUrls'
export default {
    name: "edit-price-dialog",
    data() {
        return {
            loading: false,
            visible: false,
            amount: 0,
            form: {
                value: ''
            },
            rules: {
                value: [{required: true, message: '请填写金额'}]
            }
        }
    },
    props: {
        label: {
            type: String,
            default: '金额'
        },
        valueKey: {
            type: String,
            default: ''
        },
        id: {
            type: String,
            default: ''
        },
        value: {
            type: Number,
            default: 0
        }
    },
    computed: {
        userInfo() {
            return this.$store.state.loginUserInfo
        }
    },
    mounted() {
        this.amount = this.value;
    },
    methods: {
        async handleSubmit() {
            try {
                await this.$refs['form'].validate()
                this.loading = true
                let form = {}
                form[this.valueKey] = this.form.value;
                form.id = this.id;
                const ret = await updateOrderAmountByOrderId(form);
                if (ret.success) {
                    this.$message.success('变更成功!')
                    this.$emit('success', this.form.value)
                    this.visible = false;
                    this.amount = this.form.value;
                } else this.$message.error(ret.errors[0].message)
                this.loading = false
            } catch (e) {
                console.log(e);
            }
        }
    },
    watch: {
        value() {
            this.amount = this.value;
        }
    }
}
</script>

<style scoped>

</style>